<template>
    <div class="d-flex flex-nowrap align-center justify-center">
        <v-autocomplete
            v-model="valueLocal"
            :items="items"
            :loading="isLoading"
            :search-input.sync="search"
            :label="getAutocompleteLabel"
            :rules="rules"
            item-text="label"
            item-value="id"
            :disabled="isDisabled"
            :readonly="isReadonly"
            :dense="isDense"
            hide-no-data
            :clearable="!isReadonly"
            @input="onInput" />
        <template v-if="canAdd">
            <v-btn
                text
                small
                class="ml-1"
                min-width="15px"
                max-width="20px"
                @click="add">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-dialog v-model="dialog" max-width="500px">
                <app-form
                    v-if="isEmpty(form)"
                    :key="formKey"
                    v-model="editedItem"
                    :schema="getSchema(schema.pascalPlural)"
                    :filter="filter"
                    @cancel="closeDialog"
                    @open="dialog = true"
                    @input="onAdd" />
                <component
                    :is="form"
                    v-else
                    :key="formKey"
                    v-model="editedItem"
                    :filter="filter"
                    @input="onAdd"
                    @open="dialog = true"
                    @cancel="closeDialog" />
            </v-dialog>
        </template>
    </div>
</template>

<script>
import axios from "axios";
import schemaService from "@/services/schemaService";
import searching from "@/services/searching"
import { equal, and } from "@/services/filtering"

export default {

    components: {
        appForm: () => import("@/components/AppForm")
    },
    props: {
        value: {
            type: String,
            default: null,
        },
        schema: {
            type: Object,
            default: null,
        },
        url: {
            //required if schema is not provided
            type: String,
            default: null,
        },
        listUrl: {
            //required if schema is not provided
            type: String,
            default: null,
        },
        label: {
            //required if schema is not provided
            type: String,
            default: null,
        },
        itemText: {
            //required if schema is not provided
            type: String,
            default: null
        },
        form: {
            //optional
            type: Object,
            default: null
        },
        rules: {
            type: Array,
            default: () => [],
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
        filter: {
            type: Object,
            default: null,
        },
        isDense: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isReadonly: {
            type: Boolean,
            default: false,
        },
        isAddDisabled: {
            type: Boolean,
            default: false,
        },
        customFilter: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            lastSearch: null,
            search: null,
            isLoading: false,
            from: null,
            to: null,
            autocompleteKey: 0,
            items: [],
            totalItems: [],
            dialog: false,
            editedItem: null,
            formKey: 0
        };
    },

    computed: {
        valueLocal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        getAutocompleteLabel() {
            if (this.isEmpty(this.label) && !this.isEmpty(this.schema)) {
                return this.schema.titleSingular;
            }
            return this.label;
        },
        getListUrl() {
            if (this.isEmpty(this.listUrl)) {
                return "/api/" + this.schema.pascalPlural + "/List";
            }
            return this.listUrl;
        },
        getUrl() {
            if (this.isEmpty(this.url)) {
                return "/api/" + this.schema.pascalPlural;
            }
            return this.url;
        },
        canAdd() {
            return (
                !this.isAddDisabled &&
                !this.isDisabled &&
                !this.isReadonly &&
                (!this.isEmpty(this.schema) &&
                    this.canEdit(this.schema.pascalPlural)
                    || !this.isEmpty(this.form))
            );
        },
    },

    watch: {
        async search(search) {
            if (this.isEmpty(search) || this.isEmpty(this.schema)) {
                return;
            }
            this.load();
        },
        schema() {
            if (this.isEmpty(this.schema)) {
                return;
            }
            this.load();
        },
        value: {
            immediate: true,
            async handler(value) {
                if (this.isEmpty(this.schema)) {
                    this.load();
                    return;
                }
                if (this.isEmpty(value) || Object.keys(value).every(e => value[e] === null)) {
                    this.load();
                    return;
                }
                if (this.items.some(i => i.id === value)) {
                    return;
                }
                let response = await axios.get(this.getUrl + "/" + value);
                let label = await this.getLabel(response.data, this.schema.fields);
                let item = {
                    id: response.data.id,
                    label: label,
                };
                this.items = [item];
                this.items.forEach(i => {
                    if (!this.totalItems.map(e => e.id).includes(i.id)) {
                        this.totalItems.push(i);
                    }
                });
            },
        },

        filter: {
            async handler() {
                await this.load();
                this.autocompleteKey++;
            },
        },

        customFilter: {
            async handler() {
                await this.load();
                this.autocompleteKey++;
            },
        },
    },

    methods: {
        add() {
            this.formKey++;
            this.editedItem = null;
            this.dialog = true;
        },
        onAdd(item) {
            this.valueLocal = item.id;
            this.closeDialog();
        },
        closeDialog() {
            this.dialog = false;
        },
        onInput(id) {
            let filteredItems = this.totalItems.filter(i => i.id === id);
            if (filteredItems.length === 0) {
                this.$emit("add", null);
            } else {
                this.$emit("add", this.totalItems.filter(i => i.id === id)[0]);
            }
        },
        async load() {
            if (!this.isEmpty(this.value)) {
                return;
            }
            this.lastSearch = this.search;
            this.isLoading = true;
            try {
                let params = {};
                this.setSearchFilters(params, this.schema);
                this.setFilters(params, this.schema);
                let response = await axios.post(this.getListUrl, params);
                let items = response.data.items;
                items = await this.addNicknames(items, params);

                if (this.lastSearch === this.search) {
                    let idLabels = [];
                    if (!this.isEmpty(this.itemText)) {
                        idLabels = items.map(e => {
                            return {
                                id: e.id,
                                label: e[this.itemText]
                            };
                        });
                    }
                    else {
                        idLabels = await schemaService.getIdLabels(items, this.schema.fields);
                    }
                    this.items = idLabels;
                    this.items.forEach(i => {
                        if (!this.totalItems.map(e => e.id).includes(i.id)) {
                            this.totalItems.push(i);
                        }
                    });
                }
            } finally {
                if (this.lastSearch === this.search) {
                    this.isLoading = false;
                }
            }
        },
        async addNicknames(items, params) {
            if (this.schema.pascalSingular !== "FloralVariety") {
                return items;
            }
            let allNicknamesResponse = await axios.post("api/FloralVarietyNicknames/List");
            let searchedNicknamesResponse =
                await axios.post("api/FloralVarietyNicknames/List", params);
            let allNicknames = allNicknamesResponse.data.items;
            items.forEach(e => e.name = e.name + this.getNicknames(e.id, allNicknames));
            let searchedNicknames = searchedNicknamesResponse.data.items;
            searchedNicknames.forEach(e => e.id = e.floralVarietyId);
            return items.concat(searchedNicknames);
        },
        getNicknames(floralVarietyId, floralVarietyNicknames) {
            let nicknames = floralVarietyNicknames
                .filter(e => e.floralVarietyId === floralVarietyId);
            if (nicknames.length === 0) {
                return "";
            }
            return ' (' + nicknames.map(e => e.name).join(', ') + ')';
        },
        setFilters(params, schema) {
            let filters = [];
            if (!this.isEmpty(this.customFilter)) {
                filters.push(this.customFilter);
            }
            for (let property in this.filter) {
                if (Object.prototype.hasOwnProperty.call(this.filter, property)) {
                    let field = schema.fields.filter(e => e.camelSingular === property);
                    if (this.isEmpty(field) || field.length !== 1) {
                        continue;
                    }
                    field = field[0];
                    let filter = equal(property, this.filter[property], field.nullableType);
                    filters.push(filter);
                }
            }
            if (filters.length > 0) {
                if (!this.isEmpty(params.filter)) {
                    filters.push(params.filter);
                }
                params.filter = and(filters);
            }
        },

        setSearchFilters(params, schema) {
            params.filter = searching.buildSearchFilter(schema.fields, this.search, 1);
        }
    },
};
</script>
